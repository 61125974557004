.legacy-page
{
	color: #cc0066;
	font-family: Verdana;
    background-color: #cc99ff;
	padding-right: 40px;
	padding-left: 40px;
}
.legacy-page a:link
{
	color: #ffff33;
	font-family: Verdana;
}
.legacy-page a:active
{
	color: #ff0099;
	font-family: Verdana;
}
.legacy-page a:visited
{
	color: #009933;
	font-family: Verdana;
}
.legacy-page p, .legacy-page li
{
	font-size: 10pt;
	font-family: Verdana;
}
.legacy-page h1
{
	font-weight: bold;
	font-size: 14pt;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.legacy-page h2
{
	font-weight: bold;
	font-size: 12pt;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.legacy-page h3
{
	text-decoration: underline;
	font-size: 10pt;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}